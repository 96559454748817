"use strict";

import { gsap } from 'gsap/dist/gsap';
import { Power3, Power4 } from 'gsap/dist/gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase)
gsap.config({ nullTargetWarn: false });
import barba from '@barba/core';
import { locomotiveScroll } from "/assets/js/smoothScroll";
import { initSlider } from "/assets/js/slider";

import { Loader } from 'google-maps';

import 'slick-carousel'
import 'jquery-ui';
import Player from '@vimeo/player';
// import { datepicker } from 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/widgets/autocomplete'


import jQuery from "jquery";
const $ = jQuery;

document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)

//** 100vh height fixes **/
const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

document.addEventListener("DOMContentLoaded", function () {
    //** Bug fixing test **/
    setTimeout(() => {
        // locomotiveScroll.update();
        ScrollTrigger.refresh();
    }, 500);
})

filterCustomSelectinit()
function initScript() {
    initLottie()
    // scrollHelper()
    mobilenavHandler()
    customSelectinit()
    footerLinksHalder()
    dropdownHandler()
    initFaqs()
    riPopupHandler()
    customToggleFilter()
    customaptToggleFilter()
    ebcustomToggleFilter()
    adtfilterHandler()
    formStyle()
    initTricksWords()
    letterAnim()
    slickSliderInit()
    snapSection()
    exploreTab()
    quickViewModal()
    pricePopupModal()
    allphotosModal()
    quickViewTab()
    videoModal()
    aptModal()
    buildingMapHandler()
    mapHandler()
    travelCalculator()
    mobileParallax()
    initaptresultboxSlider()
    initSlider()
    initTableScroll()
    initSticky()
    $(function () {
        $('.datepicker').datepicker({ dateFormat: 'dd-mm-yy',  minDate: '+2w'});
    });

    if(document.querySelector('#unity-container')) {
        import(`./assets/js/game-email.js`).then((imp) => imp.default());
    }
}


function initSticky(){
    const stickyEls = document.querySelectorAll('[data-scroll-sticky]');

    stickyEls.forEach(el => {
        const target = el.getAttribute('data-scroll-target');

        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: 'top top',
                endTrigger: target,
                end: 'bottom bottom',
                pin: true,
            }
        })
    });
}

//** Mobile nav handler script **/
function mobilenavHandler() {
    if ($('.header').length > 0) {
        let app = document.querySelector('body')
        let menuopenbtn = document.querySelector('.header__mobiletoggle')
        let mobilemenu = document.querySelector('.header__nav')
        let header = document.querySelector('.header')

        menuopenbtn.addEventListener('click', function () {
            menuopenbtn.classList.toggle('active')
            mobilemenu.classList.toggle('show')
            app.classList.toggle('fixed')
            header.classList.toggle('header--focus')
        })
    }
}

//** Footer links toggle handler script **/
function footerLinksHalder() {
    if ($('.footer').length > 0) {
        let footerlinkstogglebtn = document.querySelector('.footer__widget--mobiletoggle')
        let footerlinks = document.querySelector('.footer__widget--links')

        $(footerlinkstogglebtn).click(function () {
            $(footerlinks).slideToggle()
        });
    }
}



//** Custom select script **/
function dropdownHandler() {
    const menuItems = document.querySelectorAll('.header__nav--item')
    const header = document.querySelector('.header')
    let backdrop = document.querySelector('.dropdown__backdrop')

    menuItems.forEach((item) => {
        const dropdown = item.querySelector('.header__nav--item--dropdown')
        if(dropdown){
            item.addEventListener('mouseenter', function () {
                if(window.innerWidth > 1300) header.classList.add("header--focus")
            })
            item.addEventListener('mouseleave', function () {
                if(window.innerWidth > 1300) header.classList.remove("header--focus")
            })
        }
    });

    if ($('.dropdown').length > 0) {
        const dropdown = document.querySelector('.dropdown')
        const dropitems = document.querySelectorAll('.dropdown__menu--item')
        const dropitemimg = document.querySelectorAll('.dropdown__menu--item--img')
        const dropitemtexure = document.querySelectorAll('.dropdown__menu--item--texure')

        dropitems.forEach((item, index) => {
            if (index == 0) {
                item.classList.add('active')
            }
            item.addEventListener('mouseenter', function () {
                $('.dropdown__menu--item').addClass('other_hover')
                item.classList.remove('other_hover')
                if (index !== 0) {
                    item.classList.remove('active')
                }
                gsap.to('.dropdown__menu--item--img', {
                    duration: .3,
                    scale: .9,
                    autoAlpha: 0,
                })
                gsap.to('.dropdown__menu--item--img img', {
                    duration: .3,
                    scale: 1.2,
                })
                gsap.to('.dropdown__menu--item--texure', {
                    duration: .3,
                    autoAlpha: 0,
                })
                gsap.to(dropitemimg[index], {
                    duration: .5,
                    scale: 1,
                    autoAlpha: 1,
                    overwrite: true
                })
                gsap.to(dropitemimg[index].children, {
                    duration: .5,
                    scale: 1,
                    overwrite: true
                })
                gsap.to(dropitemtexure[index], {
                    duration: .5,
                    autoAlpha: 1,
                    overwrite: true
                })
            })
            item.addEventListener('mouseleave', function () {
                $('.dropdown__menu--item').removeClass('other_hover')
            })
        });

        backdrop.addEventListener('mouseenter', function () {
            dropdown.classList.add('hide')
        })
        backdrop.addEventListener('mouseleave', function () {
            dropdown.classList.remove('hide')
        })


        function imageParallax() {

            var rect = $('#dropdown')[0].getBoundingClientRect();
            var mouse = { x: 0, y: 0, moved: false };

            $(".dropdown__menu--item").mousemove(function (e) {
                mouse.moved = true;
                mouse.x = e.clientX - rect.left;
                mouse.y = e.clientY - rect.top;
            });

            // // Ticker event will be called on every frame
            gsap.ticker.add(function () {
                if (mouse.moved) {
                    parallaxIt(".dropdown__menu--item--img", -140);
                    parallaxIt(".dropdown__menu--item--texure", -100);
                }
                mouse.moved = false;
            });

            function parallaxIt(target, movement) {
                gsap.to(target, {
                    x: (mouse.x - rect.width / 2) / rect.width * movement,
                    y: (mouse.y - rect.height / 2) / rect.height * movement
                });
            }

            $(window).on('resize scroll', function () {
                rect = $('#dropdown')[0].getBoundingClientRect();
            })
        }
        imageParallax()
    }
}

function initFaqs() {
    const accordions = document.querySelectorAll(".faqs__block__item")
    const activeClass = "faqs__block__item--active"

    accordions.forEach(accordion => {
        const head = accordion.querySelector(".faqs__block__item__head")

        head.addEventListener("click", () => {
            const activeAccordion = document.querySelector(`.${activeClass}`)

            if (activeAccordion) {
                if (!(activeAccordion === accordion)) {
                    activeAccordion.classList.remove(activeClass)
                }
            }
            accordion.classList.toggle(activeClass)

            setTimeout(() => { locomotiveScroll.resize() }, 400);
        })
    })

    const faqBtns = document.querySelectorAll(".textblock--faqs__btns .linkBtn")
    faqBtns.forEach(faqBtn => {
        faqBtn.addEventListener("click", () => {
            locomotiveScroll.scrollTo(document.querySelector(`#${faqBtn.dataset.href}`))
        })
    })
}

//** RI popup handler script **/
function riPopupHandler() {
    if ($('.home-popup').length > 0) {
        const riclosebtn = document.querySelector('.home-popup--cancel')
        const ripoup = document.querySelector('.home-popup')

        riclosebtn.addEventListener('click', function () {
            ripoup.classList.add('fade-out')
        })

        const slider = document.querySelector('.home-popup--slider')
        $(slider).slick({
            dots: true,
            infinite: false,
            speed: 1000,
            fade: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            // cssEase: 'linear'
        });
    }
}

//** Custom switch filter script **/
function customToggleFilter() {
    if ($('.filter_btn').length > 0) {
        let filterall = document.querySelector('.filter_all')
        let filterpet = document.querySelector('.filter_sn')
        let filterwrapper = document.querySelector('.filter_btn')
        const filterbuttons = document.querySelectorAll('.filter_btn button')


        if ($('.filter_all').hasClass('active')) {
            filterwrapper.classList.add('all_active')
        }
        if ($('.filter_sn').hasClass('active')) {
            filterwrapper.classList.add('sn_active')
        }
        filterbuttons.forEach(item => {
            item.addEventListener('click', function () {
                item.classList.add('active')
                if ($('.filter_all').hasClass('active')) {
                    filterwrapper.classList.add('all_active')
                    filterwrapper.classList.remove('sn_active')
                    filterpet.classList.remove('active')
                }
                if ($('.filter_sn').hasClass('active')) {
                    filterwrapper.classList.add('sn_active')
                    filterwrapper.classList.remove('all_active')
                    filterall.classList.remove('active')
                }
            })
        });
        filterall.addEventListener('click', function () {
            filterpet.classList.remove('active')
            filterwrapper.classList.remove('sn_active')
            filterwrapper.classList.add('all_active')
            filterall.classList.add('active')
        })
        filterpet.addEventListener('click', function () {
            filterpet.classList.add('active')
            filterwrapper.classList.remove('all_active')
            filterwrapper.classList.add('sn_active')
            filterall.classList.remove('active')
        })
    }

}
function customaptToggleFilter() {
    if ($('.aptfilter_btn').length > 0) {
        let filterall = document.querySelector('.aptfilter_btn .filter_all')
        let filterpet = document.querySelector('.aptfilter_btn .filter_sn')
        let filterwrapper = document.querySelector('.aptfilter_btn')
        const filterbuttons = document.querySelectorAll('.aptfilter_btn button')


        if ($('.filter_all').hasClass('active')) {
            filterwrapper.classList.add('all_active')
        }
        if ($('.filter_sn').hasClass('active')) {
            filterwrapper.classList.add('sn_active')
        }
        filterbuttons.forEach(item => {
            item.addEventListener('click', function () {
                item.classList.add('active')
                if ($('.filter_all').hasClass('active')) {
                    filterwrapper.classList.add('all_active')
                    filterwrapper.classList.remove('sn_active')
                    filterpet.classList.remove('active')
                }
                if ($('.filter_sn').hasClass('active')) {
                    filterwrapper.classList.add('sn_active')
                    filterwrapper.classList.remove('all_active')
                    filterall.classList.remove('active')
                }
            })
        });
        filterall.addEventListener('click', function () {
            filterpet.classList.remove('active')
            filterwrapper.classList.remove('sn_active')
            filterwrapper.classList.add('all_active')
            filterall.classList.add('active')
        })
        filterpet.addEventListener('click', function () {
            filterpet.classList.add('active')
            filterwrapper.classList.remove('all_active')
            filterwrapper.classList.add('sn_active')
            filterall.classList.remove('active')
        })
    }

}
function ebcustomToggleFilter() {
    if ($('.ebfilter_btn').length > 0) {
        let ebfilterall = document.querySelector('.ebfilter_all')
        let ebfilterpet = document.querySelector('.ebfilter_sn')
        let ebfilterwrapper = document.querySelector('.ebfilter_btn')
        const ebfilterbuttons = document.querySelectorAll('.ebfilter_btn button')


        if ($('.ebfilter_all').hasClass('active')) {
            ebfilterwrapper.classList.add('all_active')
        }
        if ($('.ebfilter_sn').hasClass('active')) {
            ebfilterwrapper.classList.add('sn_active')
        }
        ebfilterbuttons.forEach(item => {
            item.addEventListener('click', function () {
                item.classList.add('active')
                if ($('.ebfilter_all').hasClass('active')) {
                    ebfilterwrapper.classList.add('all_active')
                    ebfilterwrapper.classList.remove('sn_active')
                    ebfilterpet.classList.remove('active')
                }
                if ($('.ebfilter_sn').hasClass('active')) {
                    ebfilterwrapper.classList.add('sn_active')
                    ebfilterwrapper.classList.remove('all_active')
                    ebfilterall.classList.remove('active')
                }
            })
        });
        ebfilterall.addEventListener('click', function () {
            ebfilterpet.classList.remove('active')
            ebfilterwrapper.classList.remove('sn_active')
            ebfilterwrapper.classList.add('all_active')
            ebfilterall.classList.add('active')
        })
        ebfilterpet.addEventListener('click', function () {
            ebfilterpet.classList.add('active')
            ebfilterwrapper.classList.remove('all_active')
            ebfilterwrapper.classList.add('sn_active')
            ebfilterall.classList.remove('active')
        })
    }

}


//**Find Apartment filter handler script **/
function adtfilterHandler() {
    if ($('.findaptbtn').length > 0) {
        let body = document.querySelector('body')
        let findaptheaderbtn = document.querySelector('.header__rightMenu--item .findaptbtn span')
        let findaptbtn = document.querySelectorAll('.findaptbtn')
        let apartmentFiltermodal = document.querySelector('.findapartmentmodal')
        let apartmentadvFiltermodal = document.querySelectorAll('.findapartmentadvmodal')
        let apartmentbacdrop = document.querySelector('.findapartmentmodal__backdrop')
        let advFiltermodalbacdrop = document.querySelector('.findapartmentadvmodal__backdrop')
        let allfilter = document.querySelectorAll('.allfilter')
        let mobilebtn = document.querySelector('.mobilefilterbtn')

        findaptbtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                if (!$('.findapartmentadvmodal').hasClass('show')) {
                    findaptheaderbtn.dataset.title = 'Close Menu'
                    mobilebtn.innerHTML = 'Close Menu'
                    apartmentFiltermodal.classList.toggle('show')
                    locomotiveScroll.scrollTo('top', {
                        duration: 0,
                    })
                    locomotiveScroll.stop()

                    if (window.innerWidth < 1030) {
                        body.classList.toggle('fixed')
                    }
                }
                if ($('.findapartmentadvmodal').hasClass('show')) {
                    apartmentFiltermodal.classList.remove('show')
                    apartmentFiltermodal.classList.remove('hide')
                    apartmentadvFiltermodal[0].classList.remove('show')

                }
                if (!$('.findapartmentmodal').hasClass('show')) {
                    findaptheaderbtn.dataset.title = 'find an apartment'
                    mobilebtn.innerHTML = 'find apartment'
                    locomotiveScroll.start()

                    if (window.innerWidth < 1030) {
                        body.classList.remove('fixed')
                    }
                }
            })
        });

        if ($('.allfilter').length > 0) {
            for (let i = 0; i < allfilter.length; i++) {
                allfilter[i].addEventListener('click', function () {
                    event.preventDefault()

                    apartmentadvFiltermodal[i].classList.toggle('show')
                    apartmentadvFiltermodal[i].classList.toggle('listpageadv')

                    if (window.innerWidth < 1030) {
                        body.classList.add('fixed')
                    }
                })
            }

        }




        apartmentbacdrop?.addEventListener('click', function () {
            apartmentFiltermodal.classList.remove('show')
            findaptheaderbtn.dataset.title = 'find an apartment'
            mobilebtn.innerHTML = 'find apartment'
            locomotiveScroll.start()

            if (window.innerWidth < 1030) {
                body.classList.remove('fixed')
            }
        })
        advFiltermodalbacdrop?.addEventListener('click', function () {
            event.preventDefault()
            apartmentadvFiltermodal.classList.remove('show')
            apartmentFiltermodal.classList.remove('hide')
            apartmentFiltermodal.classList.remove('show')
            locomotiveScroll.start()

            if (apartmentadvFiltermodal.classList.contains('listpageadv')) {
                apartmentadvFiltermodal.classList.remove('listpageadv')
            }
            if (window.innerWidth < 1030) {
                body.classList.remove('fixed')
            }
        })
    }
}


// Form style
function formStyle() {
    const inputs = document.querySelectorAll('.cssInput')
    const line = document.querySelectorAll('.cssLine')

    inputs.forEach((item, index) => {
        item.addEventListener('input', function () {
            if (item.value.length > 0) {
                item.classList.add('active')
            } else {
                item.classList.remove('active')
            }
        })
    });
}

//** Custom select script **/
function customSelectinit() {
    if ($('.csselect').length > 0) {

        $(".csselect").each(function () {
            var classes = $(this).attr("class"),
                id = $(this).attr("id"),
                name = $(this).attr("name");
            var template = '<div class="' + classes + '">';
            template += '<span class="csselect-trigger">' + $(this).attr("placeholder") + '</span>';
            template += '<div class="custom-options">';
            $(this).find("option").each(function () {
                template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
            });
            template += '</div></div>';

            $(this).wrap('<div class="csselect-wrapper"></div>');
            $(this).hide();
            $(this).after(template);
        });
        $(".custom-option:first-of-type").hover(function () {
            $(this).parents(".custom-options").addClass("option-hover");
        }, function () {
            $(this).parents(".custom-options").removeClass("option-hover");
        });
        $(".csselect-trigger").on("click", function () {
            $('html').one('click', function () {
                $(".csselect").removeClass("opened");
                locomotiveScroll.start()

            });
            $(".csselect").removeClass("opened");
            $(this).parents(".csselect").toggleClass("opened");
            $(this).parents(".csselect").toggleClass("focus");
            event.stopPropagation();

            if (locomotiveScroll.locomotiveScroll.stop) {
                locomotiveScroll.start()


            } else {
                locomotiveScroll.stop()

            }
        });
        $(".custom-option").on("click", function () {
            $(this).parents(".csselect-wrapper").find("select").val($(this).data("value")).trigger('change');
            $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
            $(this).addClass("selection");
            $(this).parents(".csselect").addClass("focus");
            $(this).parents(".csselect").removeClass("opened");
            $(this).parents(".csselect").find(".csselect-trigger").text($(this).text());
            locomotiveScroll.start()

        });
    }
}

function filterCustomSelectinit() {
    if ($('.fcsselect').length > 0) {

        $(".fcsselect").each(function () {
            var classes = $(this).attr("class"),
                id = $(this).attr("id"),
                name = $(this).attr("name");
            var template = '<div class="' + classes + '">';
            template += '<span class="csselect-trigger">' + $(this).attr("placeholder") + '</span>';
            template += '<div class="custom-options">';
            $(this).find("option").each(function () {
                template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
            });
            template += '</div></div>';

            $(this).wrap('<div class="csselect-wrapper"></div>');
            $(this).hide();
            $(this).after(template);
        });
        $(".custom-option:first-of-type").hover(function () {
            $(this).parents(".custom-options").addClass("option-hover");
        }, function () {
            $(this).parents(".custom-options").removeClass("option-hover");
        });
        $(".csselect-trigger").on("click", function () {
            $('html').one('click', function () {
                $(".fcsselect").removeClass("opened");
                locomotiveScroll.start()

            });
            $(".fcsselect").removeClass("opened");
            $(this).parents(".fcsselect").toggleClass("opened");
            $(this).parents(".fcsselect").toggleClass("focus");
            event.stopPropagation();
            if (locomotiveScroll.locomotiveScroll.stop) {
                locomotiveScroll.start()


            } else {
                locomotiveScroll.stop()

            }
        });
        $(".custom-option").on("click", function () {
            $(this).parents(".csselect-wrapper").find("select").val($(this).data("value")).trigger('change');
            $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
            $(this).addClass("selection");
            $(this).parents(".fcsselect").addClass("focus");
            $(this).parents(".fcsselect").removeClass("opened");
            $(this).parents(".fcsselect").find(".csselect-trigger").text($(this).text());
            locomotiveScroll.start()

        });
    }
}


//** Trick word script **/
function initTricksWords() {

    // Find all text with .tricks class and break each letter into a span
    var spanWord = document.getElementsByClassName("span-lines");
    for (var i = 0; i < spanWord.length; i++) {

        var wordWrap = spanWord.item(i);
        wordWrap.innerHTML = wordWrap.innerHTML.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1 <span class="span-line"> <span class="span-line-inner"> $2 </span> </span>');

    }


    var spanWord = document.getElementsByClassName("hspan-lines");
    for (var i = 0; i < spanWord.length; i++) {

        var wordWrap = spanWord.item(i);
        wordWrap.innerHTML = wordWrap.innerHTML.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '$1 <span class="hspan-line"> <span class="hspan-line-inner"> $2 </span> </span>');

    }

}

//** Letter animation script **/
function letterAnim() {
    ScrollTrigger.batch(".span-line-inner", {
        // interval: 0.1, // time window (in seconds) for batching to occur.
        // batchMax: 3,   // maximum batch size (targets)
        onEnter: batch => gsap.to(batch, { y: 0, stagger: 0.1, duration: 1.4, ease: Power4.easeOut }),
        // also onLeave, onEnterBack, and onLeaveBack
        // also most normal ScrollTrigger values like start, end, etc.
    });
}


//** Slick slider script **/
function slickSliderInit() {
    const sliders = document.querySelectorAll('.apartmentctaslider');
    if (window.innerWidth > 1030) {
        sliders.forEach(slider => {
            if (slider.querySelectorAll('.apartmentCard').length < 3) {
                slider.classList.add('apartmentctaslider--less-than')
            }

            let nextButton = document.querySelector('.apartmentctablock__sliderNext')
            let prevButton = document.querySelector('.apartmentctablock__sliderPrev')
            $(slider).slick({
                dots: false,
                infinite: false,
                speed: 1500,
                fade: false,
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                variableWidth: true,
                nextArrow: nextButton,
                prevArrow: prevButton,
                // cssEase: 'linear'
            });
        });
    }
    if ($('.exploreslider').length > 0) {
        let prevButton = document.querySelectorAll('.sliderprev')
        let nextButton = document.querySelectorAll('.slidernext')
        const exploresliders = document.querySelectorAll('.exploreslider')

        exploresliders.forEach((slider, index) => {
            $(slider).slick({
                dots: false,
                infinite: false,
                speed: 1000,
                fade: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: prevButton[index],
                nextArrow: nextButton[index],
                // cssEase: 'linear'
            });

            let sliderCursor = ".sliderCursor";
            let wrapper = document.querySelector('.exploreslider')
            slider.addEventListener('mouseenter', function () {
                gsap.set(sliderCursor, {
                    scale: 1,
                    duration: .3,
                });
            })
            slider.addEventListener('mouseleave', function () {
                gsap.set(sliderCursor, {
                    scale: 0,
                    duration: .3,
                });
            })
            gsap.set(sliderCursor, {
                // xPercent: -110,
                // yPercent: -110,
                scale: 0,
            });
            slider.addEventListener("mousemove", (e) => {
                gsap.to(sliderCursor, {
                    x: e.clientX,
                    y: e.clientY,
                    duration: .2,
                });
            });

            $(slider).click(function (e) {
                var pWidth = $(this).innerWidth();
                var pOffset = $(this).offset();
                var x = e.pageX - pOffset.left;

                if (pWidth / 2 > x) {
                    $(slider).slick('slickPrev');
                } else if (pWidth / 2 < x) {
                    $(slider).slick('slickNext');
                }
            });
            $(slider).on('mousemove', function (e) {
                var pWidth = $(this).innerWidth();
                var pOffset = $(this).offset();
                var x = e.pageX - pOffset.left;

                if (pWidth / 2 > x) {
                    $('.sliderCursor span').text('Previous')
                } else if (pWidth / 2 < x) {
                    $('.sliderCursor span').text('Next')
                }
            });

            var $status = $('.pagingInfo');
            var $slickElement = $(slider);
            $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                var i = (currentSlide ? currentSlide : 0) + 1;
                $status.text(i + ' / ' + slick.slideCount);
            });

        });



    }
    if ($('.overviewSlider').length > 0) {
        let prevButton = document.querySelectorAll('.sliderprev')
        let nextButton = document.querySelectorAll('.slidernext')

        $('.overviewSlider').slick({
            dots: true,
            infinite: false,
            speed: 1000,
            fade: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: prevButton,
            nextArrow: nextButton,
            // cssEase: 'linear'
        });

    }
    if ($('.allphotosSlider').length > 0) {

        $('.allphotosSlider').slick({
            dots: false,
            infinite: true,
            speed: 1000,
            fade: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            // cssEase: 'linear'
        });

        let sliderCursor = ".sliderCursor";
        let wrapper = document.querySelector('.allphotosmodal__content')
        gsap.set(sliderCursor, {
            xPercent: -65,
            yPercent: -65,
        });
        wrapper.addEventListener("mousemove", (e) => {
            gsap.to(sliderCursor, {
                x: e.clientX,
                y: e.clientY,
                duration: .4
            });
        });

        $('.allphotosSlider').click(function (e) {
            var pWidth = $(this).innerWidth();
            var pOffset = $(this).offset();
            var x = e.pageX - pOffset.left;

            if (pWidth / 2 > x) {
                $('.allphotosSlider').slick('slickPrev');
            } else if (pWidth / 2 < x) {
                $('.allphotosSlider').slick('slickNext');
            }
        });
        $('.allphotosSlider').on('mousemove', function (e) {
            var pWidth = $(this).innerWidth();
            var pOffset = $(this).offset();
            var x = e.pageX - pOffset.left;

            if (pWidth / 2 > x) {
                $('.sliderCursor span').text('Previous')
            } else if (pWidth / 2 < x) {
                $('.sliderCursor span').text('Next')
            }
        });

        var $status = $('.pagingInfo');
        var $slickElement = $('.allphotosSlider');
        $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.text(i + ' / ' + slick.slideCount);
        });

    }
    const imgTextSliders = document.querySelectorAll('.imgtextslider');
    imgTextSliders.forEach(imgTextSlider => {
        const prevButton = imgTextSlider.querySelector('.imgtextslider__prev');
        const nextButton = imgTextSlider.querySelector('.imgtextslider__next')
        const mainSlider = imgTextSlider.querySelector('.imgtextslider__main');
        $(mainSlider).slick({
            dots: false,
            infinite: false,
            speed: 1000,
            fade: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: nextButton,
            prevArrow: prevButton,
            // cssEase: 'linear'
        });
    });
    if ($('.eventsheroslider').length > 0) {
        let prevButton = document.querySelector('.eventsheroslider__prev')
        let nextButton = document.querySelector('.eventsheroslider__next')
        $('.eventsheroslider__main').slick({
            dots: true,
            infinite: false,
            speed: 1000,
            fade: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: nextButton,
            prevArrow: prevButton,
            // cssEase: 'linear'
        });
    }
    // if ($('.eventdetailshero').length > 0) {
    //     let prevButton = document.querySelector('.eventdetailshero__prev')
    //     let nextButton = document.querySelector('.eventdetailshero__next')
    //     $('.eventdetailshero__slider').slick({
    //         dots: false,
    //         infinite: false,
    //         speed: 1000,
    //         fade: true,
    //         arrows: true,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         nextArrow: nextButton,
    //         prevArrow: prevButton,
    //         // cssEase: 'linear'
    //     });
    // }
    if ($('.quoteslider').length > 0) {
        let prevButton = document.querySelector('.quoteslider__prev')
        let nextButton = document.querySelector('.quoteslider__next')
        $('.quoteslider__main').on('init', function(event, slick){
            $('.current-slide').text("1");
            $('.total-slides').text(slick.slideCount);
        });

        $('.quoteslider__main').on('afterChange', function (event, slick, currentSlide) {
            $('.current-slide').text(currentSlide + 1);
        })

        $('.quoteslider__main').slick({
            dots: false,
            infinite: false,
            speed: 1000,
            fade: true,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            nextArrow: nextButton,
            prevArrow: prevButton
        });

        gsap.to('.sliderCursor', {
            scale: 0,
            duration: .6,
            ease: Power3.easeInOut
        })

        var $circle = $('.sliderCursor'),
            $wrapper = $('.quoteslider');

        function moveCircle(e) {
            gsap.to($circle, 0.3, {
                css: {
                    left: e.pageX,
                    top: e.pageY
                }
            });
        }

        var flag = false;
        $($wrapper).mouseover(function () {
            flag = true;
            gsap.to($circle, 0.4, { scale: 1, autoAlpha: 1 })
            $($wrapper).on('mousemove', moveCircle);
        });
        $($wrapper).mouseout(function () {
            flag = false;
            gsap.to($circle, 0.4, { scale: 0.1, autoAlpha: 0 })
        });

        $('.quoteslider__main').click(function (e) {
            var pWidth = $(this).innerWidth();
            var pOffset = $(this).offset();
            var x = e.pageX - pOffset.left;

            if (pWidth / 2 > x) {
                $('.quoteslider__main').slick('slickPrev');
            } else if (pWidth / 2 < x) {
                $('.quoteslider__main').slick('slickNext');
            }
        });
        $('.quoteslider__main').on('mousemove', function (e) {
            var pWidth = $(this).innerWidth();
            var pOffset = $(this).offset();
            var x = e.pageX - pOffset.left;

            if (pWidth / 2 > x) {
                $('.sliderCursor span').text('Previous')
            } else if (pWidth / 2 < x) {
                $('.sliderCursor span').text('Next')
            }
        });
    }
}


//** Explore tab script **/

function exploreTab() {
    if ($('.exploreblock').length > 0) {

        const tabnavs = document.querySelectorAll('.tabnab')
        const tabitems = document.querySelectorAll('.tabcontent')

        tabnavs.forEach((item, index) => {
            item.addEventListener('click', function () {
                event.preventDefault();
                $('.tabnab').removeClass('active')
                $('.tabcontent').removeClass('active')
                item.classList.add('active')
                tabitems[index].classList.add('active')
            })
        });
    }

    $('.infoblock__features--list--slider').each(function() {
        const $slider = $(this);
        const $container = $slider.closest('.infoblock__features--list');
        const childCount = $slider.children().length;

        if (childCount > 1) {
            $container.on('init', function(event, slick) {
                $container.find('.infoblock-current-slide').text("1");
                $container.find('.infoblock-total-slides').text(slick.slideCount);
            });

            $container.on('afterChange', function(event, slick, currentSlide) {
                $container.find('.infoblock-current-slide').text(currentSlide + 1);
            });

            const prevButton = $container.find('.infoblock__prev').get(0);
            const nextButton = $container.find('.infoblock__next').get(0);

            $slider.slick({
                dots: false,
                infinite: false,
                speed: 1000,
                fade: true,
                arrows: true,
                slidesToShow: 1,
                nextArrow: nextButton,
                prevArrow: prevButton
            });
        }else{
            $container.find('.infoblock__features--list__bottom').get(0).remove();
        }
    });
}


//**  Quick view modal script **/
function quickViewModal() {
    if ($('.quickviewmodal').length > 0) {
        const quikviewopenbtn = document.querySelectorAll('.quickviewopen')
        const quikviewclosebtn = document.querySelectorAll('.modal_close')
        const quikviewmodal = document.querySelector('.quickviewmodal')
        const app = document.querySelector('body')

        quikviewopenbtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                quikviewmodal.classList.add('show')
                $('.overviewSlider').slick("refresh");
                locomotiveScroll.stop()

                if (window.innerWidth < 1030) {
                    app.classList.add('fixed')
                }
            })
        });
        quikviewclosebtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                quikviewmodal.classList.remove('show')
                locomotiveScroll.start()

                if (window.innerWidth < 1030) {
                    app.classList.remove('fixed')
                }
            })
        });
    }
}

//**  Quick view modal script **/
function pricePopupModal() {
    if ($('.pricing-popup').length > 0) {
        const priceopenbtn = document.querySelectorAll('.pricingpopupopen')
        const priceclosebtn = document.querySelectorAll('.pricing-popup__close')
        const pricemodal = document.querySelector('.pricing-popup')
        const app = document.querySelector('body')

        priceopenbtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                $(".modal_close").trigger("click")
                pricemodal.classList.add('show')
                locomotiveScroll.stop()

                if (window.innerWidth < 1030) {
                    app.classList.add('fixed')
                }
            })
        });
        priceclosebtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                pricemodal.classList.remove('show')
                locomotiveScroll.start()

                if (window.innerWidth < 1030) {
                    app.classList.remove('fixed')
                }
            })
        });
    }
}

//**  Quick view tab script **/
function quickViewTab() {
    if ($('.quickviewmodal__tab').length > 0) {
        const tabnavs = document.querySelectorAll('.quickviewmodal__tab .tabnab')
        const tabitems = document.querySelectorAll('.quickviewmodal__tab .tabcontent')

        tabnavs.forEach((item, index) => {
            item.addEventListener('click', function () {
                event.preventDefault();
                $('.tabnab').removeClass('active')
                $('.tabcontent').removeClass('active')
                item.classList.add('active')
                tabitems[index].classList.add('active')
            })
        });

        $(function () {
            $('.datepicker').datepicker({ dateFormat: 'dd-mm-yy',  minDate: '+2w'});
        });
    }
}

function aptModal() {
    if ($('.aptmodal').length > 0) {
        const aptmodalopen = document.querySelectorAll('.openaptmodal')
        const aptmodalclosebtn = document.querySelectorAll('.aptmodal__close')
        const aptmodalbackdrop = document.querySelectorAll('.aptmodal__backdrop')
        const aptmodal = document.querySelector('.aptmodal')
        const app = document.querySelector('body')
        const aptIframe = aptmodal.querySelector("iframe")

        const tabs = aptmodal.querySelectorAll('.aptmodal__video__btns__tab__toggle')
        const tabActiveClass = "aptmodal__video__btns__tab__toggle--active"
        const btnActiveClass = "aptmodal__video__btns__tab__btns__item--active"

        tabs.forEach(tab => {
            tab.addEventListener('click', function (e) {
                e.preventDefault()
                aptmodal.querySelector(`.${tabActiveClass}`).classList.remove(tabActiveClass)
                tab.classList.add(tabActiveClass)

                aptmodal.querySelector(`.${btnActiveClass}`).classList.remove(btnActiveClass)

                const firstBtn = tab.parentElement.querySelectorAll(".aptmodal__video__btns__tab__btns__item")[0]
                firstBtn.classList.add(btnActiveClass)
                aptIframe.src = firstBtn.dataset.src
            })

            const btns = tab.parentElement.querySelectorAll('.aptmodal__video__btns__tab__btns__item')
            btns.forEach(btn => {
                btn.addEventListener('click', function (e) {
                    e.preventDefault()
                    aptmodal.querySelector(`.${btnActiveClass}`).classList.remove(btnActiveClass)
                    btn.classList.add(btnActiveClass)
                    aptIframe.src = btn.dataset.src
                })
            });
        });

        const mblToggle = aptmodal.querySelector('.aptmodal__video__nav')
        const wrap = mblToggle.parentElement
        mblToggle.addEventListener('click', function (e) {
            e.preventDefault()
            if (wrap.classList.contains('aptmodal__video__nav__cont--active')) {
                wrap.classList.remove('aptmodal__video__nav__cont--active')
                mblToggle.innerText = "show menu"

            } else {
                wrap.classList.add('aptmodal__video__nav__cont--active')
                mblToggle.innerText = "hide menu"
            }
        })

        aptmodalopen.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()

                const initialSrc = aptmodal.querySelector(".aptmodal__video__btns").dataset.initial

                aptIframe.src = initialSrc

                const initialActiveBtn = aptmodal.querySelector(`.aptmodal__video__btns__tab__btns__item[data-src="${initialSrc}"]`)
                initialActiveBtn.classList.add(btnActiveClass)
                initialActiveBtn.parentElement.parentElement.querySelector(".aptmodal__video__btns__tab__toggle").classList.add(tabActiveClass)


                aptmodal.classList.add('show')
                locomotiveScroll.stop()

                if (window.innerWidth < 1030) {
                    app.classList.add('fixed')
                }
            })
        });
        aptmodalclosebtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                aptmodal.classList.remove('show')
                locomotiveScroll.start()

                aptIframe.src = ""

                if (window.innerWidth < 1030) {
                    app.classList.remove('fixed')
                }
            })
        });
        aptmodalbackdrop.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                aptmodal.classList.remove('show')
                locomotiveScroll.start()

                aptIframe.src = ""

                if (window.innerWidth < 1030) {
                    app.classList.remove('fixed')
                }
            })
        });
    }
}

//** Video modal script **/
function videoModal() {
    if ($('.videomodal').length > 0) {
        const app = document.querySelector('body')
        const videomodalopen = document.querySelectorAll('.openvideomodal')
        const videomodalclosebtn = document.querySelectorAll('.videomodal__close')
        const videomodalbackdrop = document.querySelectorAll('.videomodal__backdrop')
        const videomodalmodal = document.querySelector('.videomodal')


        videomodalopen.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                videomodalmodal.classList.add('show')
                locomotiveScroll.stop()

                if ($('.v_video').length > 0) {
                    const iframevideo = document.querySelector('.v_video')
                    const player = new Player(iframevideo);
                    player.play()
                }
                if (window.innerWidth < 1030) {
                    app.classList.add('fixed')
                }
            })
        });
        videomodalclosebtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                videomodalmodal.classList.remove('show')
                locomotiveScroll.start()

                if ($('.v_video').length > 0) {
                    const iframevideo = document.querySelector('.v_video')
                    const player = new Player(iframevideo);
                    player.pause()
                }
                if (window.innerWidth < 1030) {
                    app.classList.remove('fixed')
                }
            })
        });
        videomodalbackdrop.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                videomodalmodal.classList.remove('show')
                locomotiveScroll.start()

                if ($('.v_video').length > 0) {
                    const iframevideo = document.querySelector('.v_video')
                    const player = new Player(iframevideo);
                    player.pause()
                }
                if (window.innerWidth < 1030) {
                    app.classList.add('fixed')
                }
            })
        });
    }
}


//** All photos modal script **/
function allphotosModal() {
    gsap.set('.zoombtn', {
        scale: 0,
        autoAlpha: 0,
    })
    if ($('.allphotosmodal').length > 0) {
        const app = document.querySelector('body')
        const allphotosopenbtn = document.querySelectorAll('.allphotosmodalopen')
        const allphotosclosebtn = document.querySelectorAll('.modal_close')
        const allphotosmodal = document.querySelector('.allphotosmodal')
        const allphotosmodalcursor = document.querySelector('.sliderCursor')
        const allphotosslider = document.querySelectorAll(".allphotosmodal__content--slider")

        allphotosopenbtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                allphotosmodal.classList.add('show')
                locomotiveScroll.stop()


                gsap.to(allphotosmodalcursor, {
                    scale: 1,
                    duration: .3
                })
                if (window.innerWidth < 1030) {
                    app.classList.add('fixed')
                }
            })
        });

        allphotosclosebtn.forEach(btn => {
            btn.addEventListener('click', function () {
                event.preventDefault()
                allphotosmodal.classList.remove('show')
                locomotiveScroll.start()

                if (window.innerWidth < 1030) {
                    app.classList.remove('fixed')
                }
            })
        });

        allphotosslider.forEach(slider => {
            slider.addEventListener('mouseenter', function () {
                gsap.to(allphotosmodalcursor, {
                    scale: 1,
                    duration: .3
                })
            })
            slider.addEventListener('mouseleave', function () {
                gsap.to(allphotosmodalcursor, {
                    scale: 0,
                    duration: .6
                })
            })
        });

        if ($('.imggellary').length > 0) {
            var cursor = document.querySelector('.zoombtn');
            var imggellary = document.querySelector('.imggellary__thumb');

            imggellary.addEventListener('mousemove', onMouseMove);
            imggellary.addEventListener('mouseenter', function () {
                gsap.to('.zoombtn', {
                    scale: 1,
                    autoAlpha: 1,
                    duration: .5
                })
            });
            imggellary.addEventListener('mouseleave', function () {
                gsap.to('.zoombtn', {
                    scale: 0,
                    autoAlpha: 0,
                    duration: .5
                })
            });

            let cursorMoved = false
            function onMouseMove(e) {
                if (!cursorMoved) {
                    let tl = gsap.timeline()
                    tl.to(cursor, {
                        x: e.clientX,
                        y: e.clientY,
                        duration: .1,
                    })

                    tl.to(cursor, {
                        opacity: 1,
                        duration: .3,
                    })

                    cursorMoved = true

                } else {
                    gsap.to(cursor, .2, {
                        x: e.clientX,
                        y: e.clientY
                    })
                }
            }

        }
    }
}


function activateNavItem(snapClass, navClass) {
    gsap.to(snapClass, {
        scrollTrigger: {
            trigger: snapClass,
            start: "top center",
            end: "bottom center",
            onEnter: () => {
                $('.scrollsnap__item--nav--item').removeClass('active');
                $(navClass).addClass('active');
            },
            onLeaveBack: () => {
                $('.scrollsnap__item--nav--item').removeClass('active');
            },
            onEnterBack: () => {
                $('.scrollsnap__item--nav--item').removeClass('active');
                $(navClass).addClass('active');
            },
        }
    });
}

//** Snap scrolling script **/
function snapSection() {
    gsap.to('.scrollsnap__item--right .snapimg1wrapper', {
        y: '-100%',
        ease: "linear",
        scrollTrigger: {
            trigger: '.snap1',
            scrub: true,
            start: "top top",
        }
    })
    gsap.to('.scrollsnap__item--right .snapimg1', {
        y: '100%',
        ease: "linear",
        scrollTrigger: {
            trigger: '.snap1',
            scrub: true,
            start: "top top",
        }
    })
    gsap.to('.scrollsnap__item--right .snapimg2wrapper', {
        y: '-100%',
        ease: "linear",
        scrollTrigger: {
            trigger: '.snap2',
            scrub: true,
            start: "top top",
        }
    })
    gsap.to('.scrollsnap__item--right .snapimg2', {
        y: '100%',
        ease: "linear",
        scrollTrigger: {
            trigger: '.snap2',
            scrub: true,
            start: "top top",
        }
    })
    gsap.to('.scrollsnap__item--right .snapimg3wrapper', {
        y: '-100%',
        ease: "linear",
        scrollTrigger: {
            trigger: '.snap3',
            scrub: true,
            start: "top top",
        }
    })
    gsap.to('.scrollsnap__item--right .snapimg3', {
        y: '100%',
        ease: "linear",
        scrollTrigger: {
            trigger: '.snap3',
            scrub: true,
            start: "top top",
        }
    })

    activateNavItem('.snap1', '.snapnav1');
    activateNavItem('.snap2', '.snapnav2');
    activateNavItem('.snap3', '.snapnav3');
    activateNavItem('.snap4', '.snapnav4');
}



// 2d map script
function buildingMapHandler() {
    const buildings = document.querySelectorAll('.aptresultbox')
    const buildingNames = document.querySelectorAll('.aptresultbox__heading--title span')

    buildings.forEach((item, index) => {
        item.addEventListener('mouseenter', function () {
            let buildingname = item.dataset.building.toLowerCase()
            if (buildingname == 'xavier') {
                gsap.to('.aptresult__map svg g#HARRISON', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#AARONS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#SANDHU', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HOWS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#BUSSELL', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#XAVIER', {
                    autoAlpha: 1,
                    duration: .3,
                    ease: Power3.easeInOut
                })
            }
            if (buildingname == 'harrison') {
                gsap.to('.aptresult__map svg g#XAVIER', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#AARONS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#SANDHU', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HOWS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#BUSSELL', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HARRISON', {
                    autoAlpha: 1,
                    duration: .3,
                    ease: Power3.easeInOut
                })
            }
            if (buildingname == 'aarons') {
                gsap.to('.aptresult__map svg g#HARRISON', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#XAVIER', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#SANDHU', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HOWS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#BUSSELL', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#AARONS', {
                    autoAlpha: 1,
                    duration: .3,
                    ease: Power3.easeInOut
                })
            }
            if (buildingname == 'sandhu') {
                gsap.to('.aptresult__map svg g#HARRISON', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#AARONS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#XAVIER', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HOWS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#BUSSELL', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#SANDHU', {
                    autoAlpha: 1,
                    duration: .3,
                    ease: Power3.easeInOut
                })
            }
            if (buildingname == 'hows') {
                gsap.to('.aptresult__map svg g#HARRISON', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#AARONS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#SANDHU', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#XAVIER', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#BUSSELL', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HOWS', {
                    autoAlpha: 1,
                    duration: .3,
                    ease: Power3.easeInOut
                })
            }
            if (buildingname == 'bussell') {
                gsap.to('.aptresult__map svg g#HARRISON', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#AARONS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#SANDHU', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#HOWS', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#XAVIER', {
                    autoAlpha: .4,
                    duration: .3,
                    ease: Power3.easeInOut
                })
                gsap.to('.aptresult__map svg g#BUSSELL', {
                    autoAlpha: 1,
                    duration: .3,
                    ease: Power3.easeInOut
                })
            }
        })
        item.addEventListener('mouseleave', function () {
            gsap.to('.aptresult__map svg g', {
                autoAlpha: 1,
                duration: .3,
                ease: Power3.easeInOut
            })
        })
    });
}

//** Goggle map api **//
//const loader = new Loader('AIzaSyALXyD8d7RFT7j4xKn_PRF1LMn5ADfriPE');
const loader = new Loader('AIzaSyCrZvVM5d06QRlvaYJ6RiChByBje-E3GjU');


//** Map handler script **//
function mapHandler() {
    if ($('.localareahero__right--map').length > 0) {
        var markers = [];
        var mapGoogle = {};
        var bounds = {};
        var itemMap = [];




        const mapopenlinks = document.querySelectorAll('.localareahero__filter--list--item a')
        const mapcards = document.querySelectorAll('.mapsmcard')
        const minmapclosebtn = document.querySelectorAll('.mapsmcard__close')

        /* Show All markers*/
        /* Show All markers*/
        $.each($('.has_category'), function (i, e) {
            $.each($('.mapsmcard', e), function (ii, ee) {
                itemMap.push({ 'id': $(ee).data('id'), 'lat': $(ee).data('lat'), "lng": $(ee).data('lng'), "title": $(ee).data('title'), "category": $(e).data('category') });
            });
        });

        $('.categlist > li > a').on('click', function (e) {
            e.preventDefault();
            $('.categlist').addClass('stop_hover');
            $('.categlist li').removeClass('active_place');
            $(this).parent('li').addClass('active_place');
            clearOptionsMarker();
            filterMarkersItemSelect($(this).closest('li').find('.mapsmcard').data('id'));
        });


        // /* Show Marker All Hover*/
        $('[data-category="false"] > a').on('mouseover', function (i, e) {
            filterMarkersAllNoZoom(false);
            $(this).addClass('hover');
        });

        $('.localareahero__filter--list > li:not(.active) > a').on('mouseout', function (i, e) {
            if ($(this).closest('li').hasClass('active') == false && $(this).closest('li').hasClass('in_active') == false) {
                filterMarkersAllNoZoom(false);
                $('[data-category="false"]').addClass('hover');
            }
        });

        // /* Show Marker Category Hover*/
        $('.has_category > a').on('mouseover', function (i, e) {
            if ($(this).closest('.categlist').hasClass('stop_hover') === false && $(this).closest('li').hasClass('in_active') == false) {
                clearOptionsMarker();
                filterMarkersAllNoZoom($(this).closest('.has_category').data('category'));
                $('[data-category="false"]').removeClass('hover');
            }
        });

        // /* Show Marker Category Active*/
        $('.localareahero__filter--list > li > a').on('click', function (e) {
            e.preventDefault();
            if ($(this).closest('li').hasClass('has_category')) {
                clearOptionsMarker();
                $(this).closest('.has_category').addClass('active');
                $('.localareahero__filter--list').addClass('open_categor');
                filterMarkers($(this).closest('.has_category').data('category'));
                if ($(this).closest('li').hasClass('in_active')) {
                    $(this).closest('li').removeClass('in_active')
                }
            }
        });


        $('.localareahero__filter--list > li > .icon').on('click', function (e) {
            e.preventDefault();
            if (!$(this).closest('li').hasClass('in_active')) {
                clearOptionsMarker();
                $(this).closest('li').addClass('in_active')
                hideMarkers($(this).closest('.has_category').data('category'));
            } else {
                filterMarkersAllNoZoom(false);
                $(this).closest('li').removeClass('in_active')
            }
        });


        // /* Show Marker item Hover*/
        $('.categlist > li > a').on('mouseover', function (e) {
            if ($(this).closest('.categlist').hasClass('stop_hover') === false) {
                clearOptionsMarker();
                $('.categlist li').removeClass('active_place');
                filterMarkersItem($('.has_category.active').data('category'), $(this).closest('li').find('.mapsmcard').data('id'));
            }
        });

        $('.categlist > li > a').on('click', function (e) {
            e.preventDefault();
            $('.categlist').addClass('stop_hover');
            $('.categlist li').removeClass('active_place');
            $(this).parent('li').addClass('active_place');
            clearOptionsMarker();
            filterMarkersItemSelect($(this).closest('li').find('.mapsmcard').data('id'));
        });


        /* Close place*/
        $('.localareahero__filter--list .mapsmcard__close').on('click', function () {
            $('.categlist').removeClass('stop_hover');
            $('.localareahero__filter--list').removeClass('active');
            $('.categlist__item').removeClass('active_place');
            clearOptionsMarker();
            filterMarkersItem($('.has_category.active').data('category'), false);
            if (window.innerWidth < 1030) {
                $('.localareahero__filter--list').removeClass('active');
                $('.localareahero__filter--list .mapsmcard').removeClass('mobile_active');
                $('.categ__back').show()
            }
        });

        $('.categ__back').on('click', function (i, e) {
            event.preventDefault()
            $('.has_category').removeClass('active');
            $('.categlist li').removeClass('active_place');
            $('.localareahero__filter--list').removeClass('open_categor');
            $('.mobile_filter_btn').text('All')
            clearOptionsMarker();
            filterMarkersAll(false);
        });

        loader.load().then(function (google) {
            mapGoogle = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 50.4494694, lng: 30.5208543 },
                zoom: 12,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#c6b7a9"
                            },
                            {
                                "lightness": "-21"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "weight": "2.00"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-4"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-4"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#c6b7a9"
                            },
                            {
                                "lightness": "-25"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "weight": "0.01"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#c6b7a9"
                            },
                            {
                                "lightness": "-22"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-13"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "lightness": "63"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-10"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.airport",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.bus",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "all",
                        "stylers": [
                            {
                                "hue": "#ffb600"
                            },
                            {
                                "saturation": "-78"
                            },
                            {
                                "lightness": "-12"
                            },
                            {
                                "gamma": "0.92"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "lightness": "-61"
                            },
                            {
                                "saturation": "-35"
                            },
                            {
                                "gamma": "5.20"
                            },
                            {
                                "color": "#c52f2f"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-47"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#b4d9d6"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#b4d9d6"
                            },
                            {
                                "lightness": "-18"
                            }
                        ]
                    }
                ],
                mapTypeControl: false,
            })
            bounds = new google.maps.LatLngBounds();
            $.each(itemMap, function (i, e) {

                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(e.lat, e.lng),
                    map: mapGoogle,
                    title: e.title,
                    category: e.category,
                    itemID: e.id,
                    icon: {
                        url: `${appPhpVars.themeUrl}/assets/images/placeholder.svg`,
                        size: new google.maps.Size(17, 24)
                    }
                });
                marker.addListener('click', function () {
                    if (window.innerWidth > 766) {
                        // $('.localareahero__filter--list--item').addClass('open_categor');
                        $('.localareahero__filter--list li[data-category="' + marker.category + '"]').addClass('active');
                        $('.localareahero__filter--list li[data-category="' + marker.category + '"] a').addClass('active');

                        $('.categlist').addClass('stop_hover');
                        $('.categlistt li').removeClass('active_place');
                        $('.localareahero__filter--list .mapsmcard[data-id="' + marker.itemID + '"]').closest('li').addClass('active_place');
                        $('.localareahero__filter--list .mapsmcard[data-id="' + marker.itemID + '"]').closest('.categ').addClass('active');
                        filterMarkersItemSelect(marker.itemID);
                    }
                    else {
                        $('.localareahero__filter--list li[data-category="' + marker.category + '"]').addClass('active');
                        $('.localareahero__filter--list li[data-category="' + marker.category + '"] a').addClass('active');
                        $('.categlist').addClass('stop_hover');
                        $('.categlistt li').removeClass('active_place');
                        $('.localareahero__filter--list .mapsmcard[data-id="' + marker.itemID + '"]').closest('li').addClass('active_place');
                        $('.localareahero__filter--list .mapsmcard[data-id="' + marker.itemID + '"]').closest('.categ').addClass('active');
                        $('.mobile_filter_btn').text($('.localareahero__filter--list .mapsmcard[data-id="' + marker.itemID + '"]').closest('li').children('a').text())
                        $('.localareahero__filter--list').addClass('active');
                        $('.localareahero__filter--list .mapsmcard[data-id="' + marker.itemID + '"]').addClass('mobile_active');
                        $('.categ__back').hide()
                    }
                });

                bounds.extend(marker.position);
                markers.push(marker);
            });

            var cmsmarker = new google.maps.Marker({
                position: new google.maps.LatLng('51.54179888506413', '-0.006651004496905633'),
                map: mapGoogle,
                title: 'Copper Maker Square',
                // category: e.category,
                itemID: 'cmsid001',
                icon: {
                    url: `${appPhpVars.themeUrl}/assets/images/cms-pin.svg`,
                    // size: new google.maps.Size(17, 24)
                }
            });
            cmsmarker.addListener('click', function () {
                $('.cmspincard').addClass('show')
            });

            $('.cmspincard__close').click(function () {
                $('.cmspincard').removeClass('show')
            })

            mapGoogle.setCenter(bounds.getCenter());
            mapGoogle.fitBounds(bounds);

        });

        function filterMarkersAllNoZoom(category) {
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];
                marker.setVisible(true);
                if (marker.category == category || category === false) {
                    marker.setIcon(`${appPhpVars.themeUrl}/assets/images/placeholder.svg`);
                } else {
                    marker.setIcon(`${appPhpVars.themeUrl}/assets/images/no_active_placeh.svg`);
                }
            }
        }

        function filterMarkersAll(category) {
            bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];
                marker.setVisible(true);
                if (marker.category == category || category === false) {
                    marker.setIcon(`${appPhpVars.themeUrl}/assets/images/placeholder.svg`);
                    bounds.extend(marker.position);
                } else {
                    marker.setIcon(`${appPhpVars.themeUrl}/assets/images/no_active_placeh.svg`);
                }
            }
            mapGoogle.setCenter(bounds.getCenter());
            mapGoogle.fitBounds(bounds);
        }

        function hideMarkers(category) {
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];
                marker.setVisible(true);
                if (marker.category == category || category === false) {
                    if (!$('.has_category ').hasClass('.in_active')) {
                        marker.setVisible(false);
                    } else {
                        marker.setVisible(true);
                    }
                }
            }
        }
        function filterMarkers(category) {
            bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];
                if (marker.category == category || category === false) {
                    marker.setVisible(true);
                    bounds.extend(marker.position);
                } else {
                    marker.setVisible(false);
                }
            }
            mapGoogle.setCenter(bounds.getCenter());
            mapGoogle.fitBounds(bounds);
        }

        function filterMarkersItem(category, itemID) {
            if (category == 'undefined' || category == undefined) return;
            bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];
                if (marker.category == category || category === false) {
                    marker.setVisible(true);
                    if (marker.itemID == itemID || itemID === false) {
                        marker.setIcon(`${appPhpVars.themeUrl}/assets/images/placeholder.svg`);
                    } else {
                        marker.setIcon(`${appPhpVars.themeUrl}/assets/images/no_active_placeh.svg`);
                    }
                    bounds.extend(marker.position);
                } else {
                    marker.setVisible(false);
                }
            }
            mapGoogle.setCenter(bounds.getCenter());
            mapGoogle.fitBounds(bounds);
        }


        function filterMarkersItemSelect(itemID) {
            bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];
                if (marker.itemID == itemID || itemID === false) {
                    marker.setIcon(`${appPhpVars.themeUrl}/assets/images/placeholder.svg`);
                    if (window.innerWidth < 766) {
                        marker.setOptions({
                            labelContent: marker.title,
                            labelAnchor: new google.maps.Point(0, 50),
                            labelClass: "mapLable",
                            labelStyle: { opacity: 1.0 }
                        });
                    }
                    marker.setVisible(true);
                    bounds.extend(marker.position);
                } else {
                    marker.setVisible(false);
                }
            }

            mapGoogle.setZoom(18);
            var offsetx, offsety = 0;
            if (window.innerWidth > 766) {
                offsetx = 250 - 23;
            }
            offsetCenter(bounds.getCenter(), offsetx, offsety);
        }

        function offsetCenter(latlng, offsetx, offsety) {
            var scale = Math.pow(2, mapGoogle.getZoom());
            var worldCoordinateCenter = mapGoogle.getProjection().fromLatLngToPoint(latlng);
            var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);
            var worldCoordinateNewCenter = new google.maps.Point(
                worldCoordinateCenter.x - pixelOffset.x,
                worldCoordinateCenter.y + pixelOffset.y
            );
            var newCenter = mapGoogle.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
            mapGoogle.setCenter(newCenter);
        }


        function clearOptionsMarker() {
            if (window.innerWidth < 766) {
                for (var i = 0; i < markers.length; i++) {
                    var marker = markers[i];
                    marker.setOptions({
                        labelContent: '',
                        labelAnchor: false,
                        labelClass: false,
                        labelStyle: false
                    });
                }
            }
        }


        if (window.innerWidth < 1030) {
            mobileFilter()
        }
        function mobileFilter() {
            const mainFilterBtn = document.querySelector('.mobile_filter_btn')
            const mainFilter = document.querySelector('.localareahero__filter--list')
            const mobileClose = document.querySelector('.mobile_close')
            const categFilteritems = document.querySelectorAll('.categlist li a')

            const mainFilterItem = document.querySelectorAll('.localareahero__filter--list li a')

            mainFilterBtn.addEventListener('click', function () {
                mainFilter.classList.toggle('active')
            })
            mobileClose.addEventListener('click', function () {
                mainFilter.classList.toggle('active')
            })

            mainFilterItem.forEach(item => {
                item.addEventListener('click', function () {
                    event.preventDefault()
                    mainFilterBtn.innerHTML = item.innerHTML
                    if (item.classList.contains('categ__back')) {
                        mainFilterBtn.innerHTML = 'All'
                    }
                })
            });


            categFilteritems.forEach((item, index) => {
                item.addEventListener('click', function () {
                    event.preventDefault()
                    mainFilter.classList.remove('active')
                })
            });
        }

    }
}

//**Travel calculator script **//
function travelCalculator() {
    if ($('.tcopen').length > 0) {
        const body = document.querySelector('body')
        const tcopen = document.querySelector('.tcopen')
        const tcmodal = document.querySelector('.travelcalculator')
        const tcmodalbackdrop = document.querySelector('.travelcalculator__backdrop')
        const tcmodalclose = document.querySelector('.travelcalculator__close')

        tcopen.addEventListener('click', function () {
            event.preventDefault()
            tcmodal.classList.add('open')
            locomotiveScroll.stop()

            if (window.innerWidth < 1030) {
                $(body).addClass('fixed')
            }
        })
        tcmodalbackdrop.addEventListener('click', function () {
            tcmodal.classList.remove('open')
            locomotiveScroll.start()

            if (window.innerWidth < 1030) {
                $(body).removeClass('fixed')
            }
        })
        tcmodalclose.addEventListener('click', function () {
            tcmodal.classList.remove('open')
            locomotiveScroll.start()

            if (window.innerWidth < 1030) {
                $(body).removeClass('fixed')
            }
        })

        /*var marker = new google.maps.Marker({
            position: new google.maps.LatLng(e.lat, e.lng),
            map: mapGoogle,
            title: e.title,
            category: e.category,
            itemID: e.id,
            icon: {
                url: `${appPhpVars.themeUrl}/assets/images/placeholder.svg`,
                size: new google.maps.Size(17, 24)
            }
        });*/

        var map, directionsRenderer;
        var destinationTitle = 'Coppermaker Square';
        var destinationLocation = { lat: 51.544044183017554, lng: -0.008796772613121118 };
        var markersArray = [];

        const calcDistance = function () {
            var $dist = $('.travelcalculator__distance');
            $dist.addClass('hidden');
            directionsRenderer.setMap(null);
            if (markersArray.length === 2) {
                var directionsService = new google.maps.DirectionsService();
                var request = {
                    origin: markersArray[1].position,
                    destination: markersArray[0].position,
                    travelMode: $('[name=travel_mode]').val(),
                    unitSystem: google.maps.UnitSystem.METRIC
                };

                directionsService.route(request, function (response, status) {
                    if (status === 'OK') {
                        directionsRenderer.setMap(map);
                        directionsRenderer.setDirections(response);
                        for (const r of response.routes) {
                            for (const l of r.legs) {
                                $dist.find('.travelcalculator__distance--time').text(l.duration.text);
                                $dist.find('.travelcalculator__distance--distance').text($('.travelcalculator-modal-input').val() + ' (' + l.distance.text + ')');
                            }
                        }
                        $dist.removeClass('hidden');
                    }
                });
            }

            console.log($dist)
        }

        const setMarkersMap = function (map) {
            for (var mark of markersArray) {
                mark.setMap(map);
            }
        }

        const resetMarkers = function () {
            setMarkersMap(null);
            markersArray = [];

            var start = new google.maps.LatLng(destinationLocation.lat, destinationLocation.lng);
            var startMarker = new google.maps.Marker({
                position: start,
                title: destinationTitle,
                icon: {
                    url: `${appPhpVars.themeUrl}/assets/images/placeholder.svg`,
                    size: new google.maps.Size(17, 24)
                }
            });
            markersArray.push(startMarker);
            setMarkersMap(map);
        }


        loader.load().then(function (google) {
            map = new google.maps.Map(document.getElementById('tcmap'), {
                center: destinationLocation,
                zoom: 12,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#c6b7a9"
                            },
                            {
                                "lightness": "-21"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "weight": "2.00"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-4"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-4"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#c6b7a9"
                            },
                            {
                                "lightness": "-25"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "weight": "0.01"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#c6b7a9"
                            },
                            {
                                "lightness": "-22"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-13"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "lightness": "63"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-10"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.airport",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.bus",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "all",
                        "stylers": [
                            {
                                "hue": "#ffb600"
                            },
                            {
                                "saturation": "-78"
                            },
                            {
                                "lightness": "-12"
                            },
                            {
                                "gamma": "0.92"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "lightness": "-61"
                            },
                            {
                                "saturation": "-35"
                            },
                            {
                                "gamma": "5.20"
                            },
                            {
                                "color": "#c52f2f"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#dfdbd1"
                            },
                            {
                                "lightness": "-47"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#b4d9d6"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#b4d9d6"
                            },
                            {
                                "lightness": "-18"
                            }
                        ]
                    }
                ],
                mapTypeControl: false,
            });
            directionsRenderer = new google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            resetMarkers();
            // var bounds = new google.maps.LatLngBounds();
            // mapGoogle.setCenter(bounds.getCenter());
            // mapGoogle.fitBounds(bounds);
        });

        $('.travelcalculator-input').autocomplete({
            source: function (request, response) {
                var data = [];
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ 'address': request.term }, function (results, status) {
                    if (status !== "OK") {
                        response(data);
                        return;
                    }
                    for (var res of results) {
                        data.push({
                            label: res.formatted_address,
                            value: res.formatted_address,
                            components: res.address_components,
                            address: res.formatted_address,
                            location: { lat: res.geometry.location.lat(), lng: res.geometry.location.lng() }
                        })
                    }
                    response(data);
                });

            },
            minLength: 3,
            select: function (event, ui) {
                var $target = $(event.target);
                console.log("select", $target.hasClass('travelcalculator-form-input'), $target.hasClass('travelcalculator-modal-input-destination'), !$target.hasClass('travelcalculator-modal-input-destination'))
                if ($target.hasClass('travelcalculator-form-input')) {
                    $target.data('lat', ui.item.location.lat).attr('data-lat', ui.item.location.lat);
                    $target.data('lng', ui.item.location.lat).attr('data-lng', ui.item.location.lat);
                    $('.travelcalculator-modal-input').val(ui.item.value);
                }

                if ($target.hasClass('travelcalculator-modal-input-destination')) {
                    destinationLocation = { lat: ui.item.location.lat, lng: ui.item.location.lng }
                    destinationTitle = ui.item.value;
                }

                var mark1 = markersArray[1];
                resetMarkers();

                if (!$target.hasClass('travelcalculator-modal-input-destination')) {
                    markersArray.push(new google.maps.Marker({
                        position: new google.maps.LatLng(ui.item.location.lat, ui.item.location.lng),
                        title: ui.item.value,
                        icon: {
                            url: `${appPhpVars.themeUrl}/assets/images/placeholder.svg`,
                            size: new google.maps.Size(17, 24)
                        }
                    }));
                    setMarkersMap(map);
                }
                else if (mark1) {
                    markersArray.push(mark1);
                    setMarkersMap(map);
                }

                console.log("calcdist")
                calcDistance();
            }
        });

        $('body').on('click', '.travelcalculator-change-destination-btn', function () {
            var $wrap = $(this).parent();
            $wrap.children('a, p').remove();
            $wrap.removeClass('travelcalculator__content--form--more');
            $wrap.addClass('travelcalculator__content--form--input');
            $wrap.find('.travelcalculator_start_input_wrapper').removeClass('hidden');
        });

        $('body').on('change', '[name=travel_mode]', calcDistance);

    }
}

//** Mobile parallax  scroll **//
function mobileParallax() {
    if ($('.mobilePrallax').length > 0 && window.innerWidth < 1030) {
        gsap.to(".mobilePrallax", {
            yPercent: 15,
            ease: Power3.none,
            scrollTrigger: {
                trigger: ".mobilePrallax",
                start: "top bottom",
                end: "bottom top",
                scrub: true
            },
        });
    }
}


//** Loading animation script **//
function homeheroonload() {
    // gsap.to('.loader', {
    //     autoAlpha: 0,
    //     duration: .3,
    // })
    homeheroloadwithlogo()
    homeheroscrollto()
    homeheroslider()

    gsap.timeline({
        delay: .3
    })
    .fromTo(".hero__bg", {
        scale: 1.1
    },
    {
        scale: 1,
        duration: 1
    })
    .fromTo(".hero__bottom__quicklinks > p, .hero__bottom__quicklinks__list > li", {
        opacity: 0
    },
    {
        opacity: 1,
        stagger: .08,
    }, "<+=1.4")
    .fromTo(".hero__scroll", {
        scaleY: 0
    },
    {
        scaleY: 1,
        delay: .1
    })

    if ($('.home-popup').length > 0) {
        gsap.set('.home-popup', { x: '150%' });

        if ($('.hero').length > 0) {
            setTimeout(() => {
                ScrollTrigger.create({
                    trigger: '.hero',
                    start: 'top top+=950',
                    end: 'center top',
                    onEnter: () => {
                        gsap.to('.home-popup', {
                            x: 0,
                            autoAlpha: 1,
                            duration: .8,
                            ease: Power3.easeInOut
                        });
                    },
                    onLeave: () => {
                        gsap.to('.home-popup', {
                            x: '100%',
                            autoAlpha: 0,
                            duration: .8,
                            ease: Power3.easeInOut
                        });
                    },
                    onLeaveBack: () => {
                        gsap.to('.home-popup', {
                            x: '100%',
                            autoAlpha: 0,
                            duration: .8,
                            ease: Power3.easeInOut
                        });
                    },
                    onEnterBack: () => {
                        gsap.to('.home-popup', {
                            x: 0,
                            autoAlpha: 1,
                            duration: .8,
                            ease: Power3.easeInOut
                        });
                    }
                });
            }, 5000);
        }
    }

    function homeheroloadwithlogo() {
        let heroVideo = document.querySelector(".hero video[autoplay]")
        if (heroVideo) {
            if (heroVideo.readyState >= 3) {
                heroVideo.play()

            } else {
                heroVideo.addEventListener('loadeddata', function () {
                    heroVideo.play()
                }, false);
            }
        }
    }

    function homeheroscrollto(){
        const scrollBtn = document.querySelector('.hero__scroll')
        if(!scrollBtn) return;

        const nextElement = document.querySelector('.hero + *')
        scrollBtn.addEventListener('click', function () {
            locomotiveScroll.scrollTo(nextElement)
        })
    }

    function homeheroslider(){
        $(".hero__bg__slider").slick({
            dots: false,
            infinite: true,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 4500,
            fade: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            // cssEase: 'linear'
        });
    }
}

//** Page transition **//

let loaderanim
async function initLottie(){
    if (window.innerWidth < 1030 || ("ontouchstart" in document.documentElement)) return;
    const module = await import(`./assets/js/lottie.js`);
    loaderanim = module.default();
}

function transOut() {

    const outTl = gsap.timeline()


    outTl.to('.wrapper', {
        opacity: 0.01,
        duration: .7,
        onComplete: function () {
            if(loaderanim) loaderanim.playSegments([0, 20], true);
        }
    })
    outTl.to('.loader', {
        autoAlpha: 1,
        duration: .7
    })
}
function transIn() {
    if(loaderanim) loaderanim.playSegments([20, 0], true);
    const outTl = gsap.timeline()

    homeheroonload()

    outTl.to('.loader', {
        autoAlpha: 0,
        duration: .7,
        delay: .3,
    })
    outTl.to('.wrapper', {
        opacity: 1,
        duration: .7,
    })
}

barba.hooks.after(() => {
    locomotiveScroll.update();
});


barba.init({
    sync: true,
    preventRunning: true,
    debug: true,
    prevent: () => { return true },
    timeout: 5000,
    transitions: [
        {
            name: 'default',
            once(data) {
                initScript();
                transIn()
                setTimeout(() => {
                    wpFormSetup()
                }, 1000);
            },
            leave({ current, next, trigger }) {
                if ($('body').hasClass('fixed')) {
                    $('body').removeClass('fixed')
                }
                transOut()
                locomotiveScroll.destroy();
                let triggers = ScrollTrigger.getAll();
                triggers.forEach(trigger => {
                    trigger.kill();
                });
                const done = this.async();
                setTimeout(function () {
                    done();
                }, 1000);
            },
            afterLeave({ current, next, trigger }) {

            },
            beforeEnter({ current, next, trigger }) {
                let triggers = ScrollTrigger.getAll();
                triggers.forEach(trigger => {
                    trigger.kill();
                });

                locomotiveScroll.destroy();
            },
            enter({ current, next, trigger }) {


            },
            after({ current, next, trigger }) {
                locomotiveScroll.stop()

                setTimeout(function () {
                    locomotiveScroll.stop()

                    locomotiveScroll.init();
                    initScript();
                    locomotiveScroll.update();
                    ScrollTrigger.refresh();
                    transIn()

                    setTimeout(() => {
                        wpFormSetup()

                        let forms = document.querySelectorAll("div.wpcf7 > form")
                        if (forms.length > 0) {
                            forms.forEach(form => {
                                wpcf7.init(form);
                            });
                        }
                    }, 1000);


                }, 1000);

            },
        }
    ]
});

/* YourFuture scripts */

$.parse_str = function (b) { var a = {}; return !function (s, k) { var g, b, h, f, m, d, i, l, a, n, e, c, o, p = String(s).replace(/^&/, "").replace(/&$/, "").split("&"), t = p.length, q = function (a) { return decodeURIComponent(a.replace(/\+/g, "%20")) }, j = "undefined" != typeof window ? window : global; j.$locutus = j.$locutus || {}; var r = j.$locutus; for (r.php = r.php || {}, k || (k = j), g = 0; g < t; g++) { for (a = q((l = p[g].split("="))[0]), n = l.length < 2 ? "" : q(l[1]); " " === a.charAt(0);)a = a.slice(1); if (a.indexOf("\0") > -1 && (a = a.slice(0, a.indexOf("\0"))), a && "[" !== a.charAt(0)) { for (b = 0, c = [], e = 0; b < a.length; b++)if ("[" !== a.charAt(b) || e) { if ("]" === a.charAt(b) && e && (c.length || c.push(a.slice(0, e - 1)), c.push(a.substr(e, b - e)), e = 0, "[" !== a.charAt(b + 1))) break } else e = b + 1; for (c.length || (c = [a]), b = 0; b < c[0].length && ((" " === (i = c[0].charAt(b)) || "." === i || "[" === i) && (c[0] = c[0].substr(0, b) + "_" + c[0].substr(b + 1)), "[" !== i); b++); for (b = 0, d = k, o = c.length; b < o; b++) { if (a = c[b].replace(/^['"]/, "").replace(/['"]$/, ""), m = d, ("" === a || " " === a) && 0 !== b) { for (f in h = -1, d) d.hasOwnProperty(f) && +f > h && f.match(/^\d+$/g) && (h = +f); a = h + 1 } Object(d[a]) !== d[a] && (d[a] = {}), d = d[a] } m[a] = n } } }(b, a), a }; var $_GET = {}; document.location.search.length > 0 && ($_GET = $.parse_str(document.location.search.substr(1)))
$.fn.isInViewport = function () { var a = $(this).offset().top, c = a + $(this).outerHeight(), b = $(window).scrollTop(), d = b + $(window).height(); return c > b && a < d }
$.fn.scrollToThis = function (t) { return t || (t = 200), $([document.documentElement, document.body]).animate({ scrollTop: this.offset().top }, t), this };

$.ajaxSetup({
    headers: {
        'AJAX-NONCE': $('meta[name="ajax-nonce"]').attr('content')
    }
});

function ajaxCompleteHandler(json, $form) {
    if ($form) {
        $form.find('.form_error').each(function () {
            $(this).removeClass('form_error');
            $(this).parent().find('.form_error_txt').remove();
        });
    }
    var $msg = $form ? $form.find('.form-message') : false;
    if ($msg && $msg.length > 0) {
        $msg.removeClass('success error').html(null);
    }

    if (json.redirect) {
        document.location.href = json.redirect;
    }
    else if (json.reload) {
        document.location.reload()
    }
    else {
        //preloader.hide();
        if (json.success && $msg && $msg.length > 0) {
            $msg.html(json.success).addClass('success');
            $form[0].reset();
        }
        else if (json.errors) {
            var errors = [];
            var elTypes = ['text', 'number', 'tel', 'email', 'password'];
            $.each(json.errors, function (k, v) {
                if ($form) {
                    var $el = $form.find('[name="' + k + '"]');
                    if ($el.length > 0 && (elTypes.includes($el.attr('type')) || $el.is('textarea')) && $el.is(':visible')) {
                        $el.parent().append('<span class="form_error_txt">' + v + '</span>');
                        $el.addClass('form_error');
                    }
                    else {
                        errors.push(v)
                    }
                }
                else {
                    errors.push(v);
                }
            });
            if (errors.length > 0) {
                if ($msg && $msg.length > 0) {
                    $msg.html(errors.join('<br/>')).addClass('error');
                }
                else {
                    alert(errors.join("\n"))
                }
            }
            if ($('.form_error').length > 0) {
                $('.form_error:first').scrollToThis();
            }
        }
    }
}

function initaptresultboxSlider() {
    const aptresultbox = document.querySelectorAll('.aptresultbox__img');

    for (let i = 0; i < aptresultbox.length; i++) {
        const box = aptresultbox[i];

        const noScripts = box.querySelectorAll('noscript');
        noScripts.forEach(noScript => noScript.remove());

        if (!box.dataset.hasBeenInitted) {
            const imgs = box.querySelectorAll('img');
            let imgsLoaded = 0

            function handleImageLoad() {
                imgsLoaded++;

                if (imgsLoaded === imgs.length) {
                    $(box).slick({
                        dots: true,
                        infinite: false,
                        speed: 1000,
                        fade: true,
                        arrows: false,
                        prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
                        nextArrow: "<button type='button' class='slick-next pull-right'></button>",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        // cssEase: 'linear'
                    });

                    box.dataset.hasBeenInitted = true;
                }
            }

            imgs.forEach(img => {
                if (img.complete) {
                    handleImageLoad();

                } else {
                    img.addEventListener('load', handleImageLoad);
                }
            });
        };
    }
}

function initOverviewSlider() {
    let prevButton = document.querySelectorAll('.sliderprev')
    let nextButton = document.querySelectorAll('.slidernext')

    $('.overviewSlider').slick({
        dots: true,
        infinite: false,
        speed: 1000,
        fade: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: prevButton,
        nextArrow: nextButton,
        // cssEase: 'linear'
    });
}

function wpFormSetup() {
    document.addEventListener('wpcf7mailsent', function (event) {
        var $form = $(event.target);
        var $redirect = $form.find('[name=redirect_url]');
        if ($redirect.length > 0 && $redirect.val()) {
            document.location.href = $redirect.val();
        }
    }, false);

    var wpcf7Elm = document.querySelectorAll('.wpcf7-form');
    wpcf7Elm.forEach(el => {
        const bedsNum = el.querySelector("select[name='beds_num']")

        if (bedsNum) {
            bedsNum.onchange = () => {
                if (bedsNum.value !== "1" && bedsNum.value !== "Please select") {
                    el.querySelectorAll(".maxrent_num option, select[name='max_rent'] option").forEach(opt => {
                        if (opt.value == "£2,000 - £2,500") opt.disabled = true;
                    });

                    gsap.set(".maxrent_num .custom-option:nth-child(2), [data-name='max_rent'] .custom-option:nth-child(2)", {
                        pointerEvents: "none",
                        opacity: .5
                    })

                } else {
                    el.querySelectorAll(".maxrent_num option").forEach(opt => {
                        opt.disabled = false;
                    });

                    gsap.set(".maxrent_num .custom-option:nth-child(2), [data-name='max_rent'] .custom-option:nth-child(2), .maxrent_num .custom-option:nth-child(3), [data-name='max_rent'] .custom-option:nth-child(3)", {
                        pointerEvents: "all",
                        opacity: 1
                    })
                }
            }
        }
        el.addEventListener('submit', function (event) {
            let button = document.querySelectorAll("button[type=submit]")

            button.forEach(btn => {
                btn.disabled = true
                btn.children[0].innerHTML = "Please wait..."
                btn.children[0].dataset.title = "Please wait..."
            });

        }, false);
    });

    document.addEventListener("wpcf7invalid", function (e) {
        let button = document.querySelectorAll("button[type=submit]")

        button.forEach(btn => {
            btn.disabled = false
            btn.children[0].innerHTML = "SIGN UP"
            btn.children[0].dataset.title = "SIGN UP"
        });
    })

    const contactForm = document.querySelector("#wpcf7-f449-o1")
    const criteriaPopup = document.querySelector(".criteria")

    if (contactForm && criteriaPopup) {
        const formSubmitBtn = contactForm.querySelector("button[type=submit]")
        let criteriaAccepted = false

        formSubmitBtn.addEventListener("click", function (e) {
            if (!criteriaAccepted) {
                e.preventDefault()

                criteriaPopup.classList.add("show")
                locomotiveScroll.stop()

                dataLayer.push({ 'event': 'criteria_popup_open' });
            }
        })

        const closeCriteria = document.querySelector(".criteria__head__close")
        const acceptCriteria = document.querySelector(".criteria__footer__accept")

        if (closeCriteria && acceptCriteria) {
            closeCriteria.addEventListener("click", function (e) {
                e.preventDefault()

                criteriaPopup.classList.remove("show")
                locomotiveScroll.start()
            })

            acceptCriteria.addEventListener("click", function (e) {
                e.preventDefault()
                criteriaAccepted = true
                formSubmitBtn.click()

                criteriaPopup.classList.remove("show")
                locomotiveScroll.start()
            })
        }
    }
}

$(document).ready(function () {
    var $body = $('body');

    function loadUnits(page = 1, append = false) {
        var $wrap = $('.units-list');
        var $pagination_btn = $('.load-more-units-btn');
        var $pagination_wrap = $pagination_btn.closest('.pagination-wrap');
        $pagination_wrap.hide();
        if (!append) {
            $wrap.find('li:not(.pagination-wrap)').remove();
        }
        var filter = $.parse_str($('.filter-input').serialize());
        $.ajax({
            type: "POST",
            url: appPhpVars.ajaxUrl,
            dataType: "json",
            data: {
                action: 'ajax_load_units',
                filter: filter,
                page: page
                
            },
            beforeSend: function () {
            },
            complete: function () {
            },
            success: function (json) {
                $('.results-count').text(json.found);
                if (json.found > 0) {
                    // console.log(json.dates);
                    if (json.incentivised_units) {
                        for (var item of json.incentivised_units) {
                            var $item = $('<li class="aptresult__list--item">' + item + '</li>');
                            //$wrap.append($item);
                            $pagination_wrap.before($item);
                        }
                    }
                    else if (json.featured_unit) {
                        var $featured_item = $('<li class="aptresult__list--item aptresult__list--item--featured">' + json.featured_unit + '</li>');
                        //$wrap.append($item);
                        $pagination_wrap.before($featured_item);
                    }
                    for (var item of json.items_html) {
                        var $item = $('<li class="aptresult__list--item">' + item + '</li>');
                        //$wrap.append($item);
                        $pagination_wrap.before($item);
                    }
                }
                else {
                    //$wrap.html('<li><p>NOTHING FOUND</p></li>')
                    $pagination_wrap.before('<li><p>Sorry, no apartments with this criteria are currently available. Why not enquire about another apartment or get in touch with our leasing team to see what is available?</p></li>')
                }
                if (json.next_page) {
                    $pagination_btn.data('next_page', json.next_page).attr('data-next_page', json.next_page);
                    $pagination_wrap.show();
                }

                setTimeout(function () {
                    locomotiveScroll.resize()
                    window.dispatchEvent(new Event('resize'));

                    ScrollTrigger.refresh()
                    buildingMapHandler();
                    quickViewModal();
                    pricePopupModal();
                }, 400);
                initaptresultboxSlider();
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }

    $body.on('change', '.filter-input', function () {
        loadUnits();
    });

    $body.on('click', '.load-more-units-btn', function () {
        var p = parseInt($(this).data('next_page'));
        loadUnits(p, true)
    });

    $body.on('click', '.quickviewopen-load', function () {
        var $btn = $(this);
        $.ajax({
            type: "POST",
            url: appPhpVars.ajaxUrl,
            dataType: "json",
            data: {
                action: 'ajax_unit_quick_view',
                unit: $btn.data('unit'),
                move: $btn.data('move'),
                incentivised: $btn.data('incentivised'),
            },
            beforeSend: function () {
            },
            complete: function (response) {
                ajaxCompleteHandler(response.responseJSON);
            },
            success: function (json) {
                if (json.html) {
                    $('.quickviewmodal__content > *:not(button.modal_close)').remove();
                    $('.quickviewmodal__content button.modal_close').after(json.html);
                    quickViewTab();
                    initOverviewSlider();
                    videoModal();
                    $btn.parent().find('.quickviewopen')[0].click();
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $body.on('click', '.pricingpopup-rent', function () {
        const rentTab = document.querySelector(".tabnab--rent")
        if(rentTab) rentTab.click()
    });

    $body.on('click', '.pricingpopup-load', function () {
        var $btn = $(this);
        $.ajax({
            type: "POST",
            url: appPhpVars.ajaxUrl,
            dataType: "json",
            data: {
                action: 'ajax_unit_pricing_popup',
                unit: $btn.data('unit'),
                move: $btn.data('move'),

            },
            beforeSend: function () {
            },
            complete: function (response) {
                ajaxCompleteHandler(response.responseJSON);
            },
            success: function (json) {
                if (json.html) {
                    $('.pricing-popup .pricing > *:not(button.pricing-popup__close)').remove();
                    $('.pricing-popup button.pricing-popup__close').after(json.html);
                    setTimeout(() => {
                        const pricemodal = document.querySelector(".pricing-popup .pricing")
                        const dateField = pricemodal.querySelector('.datepicker')
                        if(dateField){
                            $(dateField).datepicker({ dateFormat: 'dd-mm-yy', minDate: '+2w', maxDate: '+90d', 
                                onSelect: function (date) {
                                    const rentBtns = pricemodal.querySelectorAll('.btnPrimary.btnCopper')
                                    rentBtns.forEach(btn => {
                                        const href = btn.getAttribute("href")
                                        const newHref = `${href.split("?lease_start_date=")[0]}?lease_start_date=${date}`
                                        btn.setAttribute("href", newHref)
                                    });
                                    getDateWisePrice($btn.data('unit'),date);
                                }
                            });
                        }
                    }, 500);
                    $btn.parent().find('.pricingpopupopen')[0].click();
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    function getDateWisePrice(unit,date){
        $.ajax({
            type: "POST",
            url: appPhpVars.ajaxUrl,
            dataType: "json",
            data: {
                action: 'ajax_unit_pricing_data_filter',
                unit: unit,
                date: date,
            },
            beforeSend: function () {
                $('.pricing-popup .pricing_inner').html('<div class="loading">Loading...</div>');
            },
            success: function (json) {
                if (json.html) {
                    $('.pricing-popup .pricing_inner').html(json.html);
                }else{
                    $('.pricing-popup .pricing_inner').html('<p>No data found</p>');
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }

    $body.on('click', '.openvideomodal', function () {
        var src = this.dataset.src;
        if (src) {
            if (src.includes("visualisationone") || src.includes("vr-360")) {
                document.querySelector("iframe.showcase").src = src

                document.querySelector("video.showcase").style.display = "none"
                document.querySelector("iframe.showcase").style.display = "block"

            } else {
                document.querySelector("video.showcase").src = src

                document.querySelector("video.showcase").style.display = "block"
                document.querySelector("iframe.showcase").style.display = "none"

                $('video.showcase').trigger('play')
            }
        }
    });

    /*locomotiveScroll.on("scroll", () => {
        if($('.units-list').length > 0) {
            var $pagination_wrap = $('.pagination-wrap');
            if ($pagination_wrap.isInViewport()) {
                $pagination_wrap.find('.load-more-units-btn').click();
            }
        }
    });*/

    function loadPosts(page = 1, append = false) {
        var $posts_wrap = $('.posts-list');
        var $pagination_wrap = $('.pagination-wrap');
        var $pagination_btn = $('.load-more-posts-btn');
        $.ajax({
            type: "POST",
            url: appPhpVars.ajaxUrl,
            dataType: "json",
            data: {
                action: 'ajax_load_posts',
                page: page,
                post_type: $pagination_btn.data('post_type'),
                taxonomy: $pagination_btn.data('taxonomy'),
                term_id: $pagination_btn.data('term_id'),
                ordering: $('[name="posts-ordering"]').val(),
                event_local_filter: $('.event-local-filter.active').data('value')
            },
            beforeSend: function () {
                $pagination_wrap.addClass('hidden');
                $pagination_btn.data('page', page).attr('data-page', page);
            },
            complete: function () {
            },
            success: function (json) {
                if (json.html) {
                    if (append) {
                        $posts_wrap.append(json.html);
                    }
                    else {
                        $posts_wrap.html(json.html);
                    }
                }
                if (json.next_page) {
                    $pagination_wrap.removeClass('hidden');
                }
                setTimeout(function () {
                    locomotiveScroll.resize()
                    window.dispatchEvent(new Event('resize'));
                    ScrollTrigger.refresh()
                }, 400);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.error(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    }

    $body.on('click', '.load-more-posts-btn', function () {
        var $btn = $(this);
        var $wrap = $(this).closest('.pagination-wrap');
        var page = parseInt($btn.data('page'));
        loadPosts(++page, true);
    });

    $body.on('change', '[name="posts-ordering"]', function () {
        loadPosts();
    });

    $body.on('click', '.event-local-filter', function () {
        loadPosts();
    })

    /*locomotiveScroll.on("scroll", () => {
        if($('.load-more-posts-btn').length > 0) {
            var $pagination_wrap = $('.pagination-wrap');
            if ($pagination_wrap.isInViewport()) {
                $pagination_wrap.find('.load-more-posts-btn').click();
            }
        }
    });*/

    $body.on('click', '.share-twitter-btn', function () {
        var title = $(this).attr('title');
        var href = $(this).attr('href');
        if (title && href) {
            var url = `http://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(href)}`
            window.open(url, "_blank", "height=400,width=600,left=400,top=100,resizable,scrollbars,toolbar=0,personalbar=0,menubar=no,location=no,directories=no,status");
            return false;
        }
    });

    $body.on('click', '.copy-link-btn', function () {
        var $text = $(this).find('textarea');
        var $span = $(this).find('span');
        if ($text.length > 0) {
            var $msg = $('.link-copied-message');
            $text.select();
            document.execCommand('copy');

            $span.fadeTo(200, 0);
            setTimeout(function () {
                $span.text('Link copied');
                $span.fadeTo(200, 1);
            }, 250);

            setTimeout(function () {
                $span.fadeTo(200, 0);
                setTimeout(function () {
                    $span.text('Copy link');
                    $span.fadeTo(200, 1);
                }, 250);
            }, 3000)
            return false;
        }
    });

    // No need of this one I guess as I have updated the script above
    // $body.on('click', '.all-filters-btn', function(){
    //     $('.findapartmentmodal').addClass('hide');
    //     $('.findapartmentadvmodal').addClass('show');
    //     $('.findaptbtn')[0].click()
    // });

    $body.on('change', '.filter-input', function () {
        var $modal_field = $(`.filter-modal-input[name="${$(this).attr('name')}"]`);
        if ($modal_field.length > 0) {
            $modal_field.val($(this).val());
        }
    });

    $body.on('click', '.category-filter-btn', function () {
        var $btn = $(this);
        var $pagination_btn = $('.load-more-posts-btn');
        var $ul = $btn.closest('ul');
        if (!$btn.hasClass('active')) {
            $ul.find('a.active').removeClass('active');
            $btn.addClass('active');
            $pagination_btn.data('taxonomy', $btn.data('taxonomy')).attr('data-taxonomy', $btn.data('taxonomy'));
            $pagination_btn.data('term_id', $btn.data('term_id')).attr('data-term_id', $btn.data('term_id'));
            loadPosts();
        }
        return false;
    });

});





function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) {
        return true;
    }
}

// You can use the function reloadOTBanner(), which is generated by the script below to force the banner to be reloaded on the page. This function should be called on any page change within the SPA.
function reloadOTBanner() {
    var otConsentSdk = document.getElementById("onetrust-consent-sdk");
    if (otConsentSdk) { otConsentSdk.remove(); }

    if (window.OneTrust != null) {
        OneTrust.Init();

        setTimeout(function () {
            OneTrust.LoadBanner();

            var toggleDisplay = document.getElementsByClassName(
                "ot-sdk-show-settings"
            );

            for (var i = 0; i < toggleDisplay.length; i++) {
                toggleDisplay[i].onclick = function (event) {
                    event.stopImmediatePropagation();
                    window.OneTrust.ToggleInfoDisplay();
                };
            }
        }, 150);
    }
}

//SHOULD BE USED ONLY ON COOKIE POLICY. TRIGGER FUNCTION BELOW TO REMOVE DUPLICATE CATEGORIES
function clearDup() {
    var sec = document.getElementById("ot-sdk-cookie-policy")
    var tally = [];
    for (var i = sec.length - 1; i >= 0; i--) {
        if (tally[sec[i].firstChild.innerText] === undefined) {
            tally[sec[i].firstChild.innerText] = 1;
        } else {
            //console.log(i,sec[i].firstChild.innerText);
            sec[i].remove();
            //return true;
        }
    }
    //return false;
}

function initTableScroll(){
    const tables = document.querySelectorAll('.table-cont')

    tables.forEach(table => {
        const tableEls = table.querySelectorAll('td > h5, td > p')

        gsap.set(tableEls, {
            yPercent: 200,
            opacity: 0.01
        })
        gsap.to(tableEls, {
            scrollTrigger: {
                trigger: table,
                start: 'top bottom-=30%',
                once: true
            },
            opacity: 1,
            yPercent: 0,
            stagger: .03,
        })
    });
}
